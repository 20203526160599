import React from 'react'
import { graphql, Link } from 'gatsby'
import { Row, Col, Media } from 'reactstrap'
import ResponsiveEmbed from 'react-responsive-embed'

import Layout from '../components/common/Layout'
import Section from '../components/common/Section'

import CtaBlock from '../components/common/CtaBlock'
import Wildcard from '../components/common/Wildcard'
import { Zoom, Fade } from 'react-reveal'
import bg5 from '../components/2020/media/bg5.svg'
import bg4 from '../components/2020/media/bg4.svg'

const Speaker = props => (
  <>
    <Media left top>
      <img
        src={props.image.fixed.src}
        className=" img-fluid rounded align-self-start mr-3 "
        width={80}
      />
    </Media>
    <Media body>
      <h4 className="my-0">{props.name}</h4>
      <p className="sub-head mt-1">
        {props.position} {props.company}
      </p>
    </Media>
  </>
)

const Talk = ({ title, speaker, abstract, youtubeUrl }) => (
  <div className="reveal mt-5">
    <Fade left distance="20x">
      <h3 className="mt-1">{title}</h3>

      <Media className="margin-top ">
        {speaker.map(speaker => (
          <Speaker {...speaker} key={speaker.id} />
        ))}
      </Media>
      <p className="indent dashed my-3">{abstract.abstract}</p>
      <ResponsiveEmbed src={youtubeUrl} allowFullScreen />
    </Fade>
  </div>
)

const HardFact = ({ headline, markdown }) => (
  <Col md={6}>
    <div className="service-link">
      <h4 className="dashed dashed--hover in-view__child in-view__child--in">
        {headline}
      </h4>

      <div className="indent text-body-1">
        <p>{markdown}</p>
      </div>
    </div>
  </Col>
)

export default ({ data }) => {

  const hardfacts = data.hardfacts.edges.map(({ node }, i) => <HardFact {...node} key={`hf-${i}`} />)

  const talks = data.winningTalks.edges.map(({ node }) => (
    <Talk {...node} key={node.id} />
  ))

  return (
    <Layout>
      <Section section="section section--fit" bg={bg5}>
        <div className="page-header">
          <h1><span className="outline">Speaking</span> at DEVDAY</h1>
        </div>
        <Fade left distance="20px">
          <div className="reveal reveal__copy reveal--right">
            <Row>{hardfacts}</Row>
          </div>
        </Fade>

        <Wildcard
          align="left"
          color="tertiary"
          xlSize={{ size: 12 }}
          title="Audience, subjects and inspiration "
          revealClasses="reveal--darken"
          backgroundClass="bg-image"
          backgroundStyle={{ backgroundImage: `url(https://res.cloudinary.com/turbinekreuzberg/image/upload/v1559136614/devday19/faro/75-P1210013.jpg)` }}
          content={
            <Fade left distance="20px">
              <div className="indent" dangerouslySetInnerHTML={{ __html: data.inspireme.html }} />
            </Fade>
          }
        />

      </Section>

      <CtaBlock backgroundColor="tertiary">
        <Link to="/call-for-speakers" className="btn btn--primary">
          Give a talk at Dev Day
        </Link>
      </CtaBlock>}

      <Section bg={bg4} xlSize={{ size: 12 }}>
        <h2>Talks that won the <br /> <span className="outline">Best Speaker Award</span></h2>
        {talks}
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    hardfacts: allHardfactsJson {
      edges {
        node {
          key
          markdown
          headline
        }
      }
    }
    inspiration: allInspiringTalksJson {
      edges {
        node {
          key
          title
          subtitle
        }
      }
    }
    inspireme: markdownRemark(frontmatter: { path: { eq: "/2020/inspire-me" } }) {
      html
    }
    winningTalks: allContentfulTalk(
      filter: {
        node_locale: { eq: "de" }
        winner: { eq: true }
      }
    ) {
      edges {
        node {
          id
          title
          youtubeUrl
          slides
          speaker {
            name
            id
            position
            company
            image {
              fixed {
                src
              }
            }
          }
          abstract {
            abstract
          }
        }
      }
    }
  }
`
